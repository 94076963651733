import axios from 'axios'
import Qs from 'qs'
import * as moment from 'moment'

export default {
  groupUsers: groupIDs => {
    return axios
      .get('/api/admin/users/group_users', {
        params: {
          users: {
            group_ids: groupIDs
          }
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  activityReport: options => {
    return axios
      .get('/api/admin/user_activity_reports/activity_report_details', {
        params: {
          group_ids: options.groupIDs,
          user_ids: options.userIDs,
          report_types: options.reportTypes,
          start_date: options.startDate,
          end_date: options.endDate
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets',
            serializeDate: date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
          })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
