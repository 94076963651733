import axios from 'axios'

export default {
  userGroups: () => {
    return axios
      .get('/api/admin/users/groups', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
