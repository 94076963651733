import axios from 'axios'

export default {
  enableMonthlyReport: options => {
    return axios
      .post(
        '/api/admin/user_activity_reports/schedule_monthly',
        {
          group_ids: options.groupIDs,
          user_ids: options.userIDs,
          report_types: options.reportTypes
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  updateMonthlyReport: options => {
    return axios
      .put(
        '/api/admin/user_activity_reports/update_report_query',
        {
          group_ids: options.groupIDs,
          user_ids: options.userIDs,
          report_types: options.reportTypes
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  disableMonthlyReport: () => {
    return axios
      .delete('/api/admin/user_activity_reports/destroy_schedule', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  userActivityReport: () => {
    return axios
      .get('/api/admin/user_activity_reports/user_activity_report', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
