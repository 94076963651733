<template>
  <PageCard
    pageName="User Activity Reports"
    :iconClass="['fa', 'fa-user-secret', 'fa-lg']"
  >
    <template slot="page_content">
      <div class="row">
        <div class="col-sm-12">
          <b-card
            class="std-border"
            body-class="p-1 activity-filter-form"
            header-class="p-2 header-with-bg"
          >
            <div slot="header">
              <div class="row fit-to-center">
                <div class="col-sm-12">
                  <div class="d-flex">
                    <h5 class="mb-0 font-weight-bold">
                      Query Builder
                    </h5>
                    <h6 class="mb-0 px-2 d-flex align-self-center">
                      (Use below options to filter user activity reports)
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-3">
              <div class="row">
                <div class="col-sm-6">
                  <v-wait for="fetchingGroups">
                    <template slot="waiting">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="9"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                    <b-form-group
                      label="Group"
                      label-for="group"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <multiselect
                        v-model="groups"
                        :options="groupsList"
                        :multiple="true"
                        :close-on-select="false"
                        :showLabels="false"
                        track-by="id"
                        label="name"
                        :placeholder="groups.length ? '' : 'Select Groups'"
                        :class="[
                          {
                            'is-invalid': $v.groups.$error,
                            'is-valid': !$v.groups.$invalid
                          },
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                        @close="getGroupUsers"
                      >
                        <template
                          slot="selection"
                          v-if="groups.length"
                          slot-scope="{ values }"
                        >
                          {{ values.length }}
                          {{ values.length | pluralize('group') }} selected
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="!$v.groups.required"
                        >at least one group required</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <b-form-group
                      label="User"
                      label-for="user"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <multiselect
                        v-model="users"
                        :options="usersList"
                        :multiple="true"
                        :close-on-select="false"
                        :showLabels="false"
                        :loading="fetchingUsers"
                        track-by="id"
                        label="name"
                        :placeholder="groups.length ? '' : 'Select Users'"
                        :class="[
                          {
                            'is-invalid': $v.users.$error,
                            'is-valid': !$v.users.$invalid
                          },
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                        @close="validateUsers"
                      >
                        <template
                          slot="selection"
                          v-if="users.length"
                          slot-scope="{ values }"
                        >
                          {{ values.length }}
                          {{ values.length | pluralize('user') }} selected
                        </template>
                        <template slot="beforeList" v-if="usersList.length">
                          <li class="multiselect__elemet p-2 border-bottom">
                            <span
                              @click="selectOrRemoveAllUsers()"
                              class="select-all-option"
                            >
                              <b>{{ selectAllText }}</b></span
                            >
                          </li>
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="!$v.users.required"
                        >at least one user required</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <b-form-group
                      label="Report Type"
                      label-for="report_type"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <multiselect
                        v-model="reportTypes"
                        :options="reportTypesList"
                        :multiple="true"
                        :close-on-select="false"
                        :showLabels="false"
                        :placeholder="
                          reportTypes.length ? '' : 'Select Report Types'
                        "
                        track-by="value"
                        label="text"
                        :class="[
                          {
                            'is-invalid': $v.reportTypes.$error,
                            'is-valid': !$v.reportTypes.$invalid
                          },
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                      >
                        <template
                          slot="selection"
                          v-if="reportTypes.length"
                          slot-scope="{ values }"
                        >
                          {{ values.length }}
                          {{ values.length | pluralize('type') }} selected
                        </template>
                      </multiselect>
                      <b-form-invalid-feedback v-if="!$v.reportTypes.required"
                        >at least one report type
                        required</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <b-form-group
                      label="Date Range"
                      label-for="date_range"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <div class="row">
                        <div
                          class="col-sm-6 date-col"
                          :class="{
                            'is-invalid': $v.startDate.$error,
                            'is-valid': !$v.startDate.$invalid
                          }"
                        >
                          <datepicker
                            v-model="startDate"
                            placeholder="Start Date"
                            format="yyyy-MM-dd"
                            :bootstrap-styling="true"
                            class="start-date-picker"
                            @selected="validateStartDate()"
                          ></datepicker>
                          <b-form-invalid-feedback v-if="!$v.startDate.required"
                            >can't be blank</b-form-invalid-feedback
                          >
                          <b-form-invalid-feedback v-if="!$v.startDate.isBefore"
                            >should be earlier than End
                            Date</b-form-invalid-feedback
                          >
                        </div>

                        <div
                          class="col-sm-6 date-col"
                          :class="{
                            'is-invalid': $v.endDate.$error,
                            'is-valid': !$v.endDate.$invalid
                          }"
                          @focusout="validateEndDate()"
                        >
                          <datepicker
                            v-model="endDate"
                            placeholder="End Date"
                            format="yyyy-MM-dd"
                            :bootstrap-styling="true"
                            class="start-date-picker"
                            @selected="validateEndDate()"
                          ></datepicker>
                          <b-form-invalid-feedback v-if="!$v.endDate.required"
                            >can't be blank</b-form-invalid-feedback
                          >
                          <b-form-invalid-feedback v-if="!$v.endDate.isBefore"
                            >should be after Start Date</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </b-form-group>
                  </v-wait>
                </div>

                <div class="col-sm-6 px-5" v-if="showSubscription">
                  <b-card
                    class="std-border my-3 rounded card--report-schedule"
                    body-class="p-2 schedule-body"
                    header-class="p-2 schedule-header"
                  >
                    <div slot="header">
                      <div class="row fit-to-center">
                        <div class="col-sm-12">
                          <b> Schedule Monthly Report </b>
                        </div>
                      </div>
                    </div>
                    <v-wait for="fetchingUserSchedule">
                      <template slot="waiting">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="5"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>
                      <div>
                        <p>
                          Enabling this feature will send a monthly user
                          activity report applying these filters(except date
                          range) to your registered email.
                          <br />
                          <b class="d-inline-block pt-2">Note:</b> Date range
                          will always between start date and end date of month
                          for scheduled reports.
                        </p>
                      </div>
                      <div class="text-center mt-2">
                        <button
                          type="submit"
                          class="btn btn-secondary rounded py-1 px-2 mt-1 fw-btn"
                          @click="disableScheduledReport"
                          :disabled="!reportScheduled"
                        >
                          Disable
                        </button>
                        <button
                          type="submit"
                          class="btn btn-success rounded py-1 px-2 mt-1 mx-3 btn-enable fw-btn"
                          @click="updateScheduledReport()"
                          v-if="reportScheduled"
                        >
                          <b>Update</b>
                        </button>
                        <button
                          type="submit"
                          class="btn btn-success rounded py-1 px-2 mt-1 mx-3 btn-enable fw-btn"
                          @click="enableScheduledReport()"
                          v-else
                        >
                          <b>Enable</b>
                        </button>
                      </div>
                    </v-wait>
                  </b-card>
                </div>
              </div>

              <div class="row pt-3">
                <div class="col-6 d-flex justify-content-center">
                  <div class="px-1">
                    <button
                      type="button"
                      class="btn fw-btn btn-secondary rounded"
                      @click="resetQuery"
                    >
                      Reset
                    </button>
                  </div>
                  <div class="px-1">
                    <button
                      type="button"
                      class="btn fw-btn btn-primary rounded"
                      @click="fetchActivityReport"
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>

      <b-card
        class="std-border my-3 card--activity-report"
        body-class="p-1"
        header-class="p-2 header-with-bg"
        v-if="showReports"
      >
        <div slot="header">
          <div class="row fit-to-center">
            <div class="col-sm-6">
              <div class="page-header-left">
                <div>
                  <h5 class="mb-0">
                    <b>Query Results</b>
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="page-header-right">
                <div class="">
                  <i
                    class="cuis-cloud-download font-2xl download-icon"
                    title="Download CSV"
                    @click="downloadCSVReport()"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="fetchingReports">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </div>
        <b-table
          striped
          hover
          :fields="reportFields"
          :items="userReports"
          small
          responsive
        >
          <template v-slot:cell(event_detail)="data">
            <div v-if="data.item.event_detail">
              {{ data.item.event_detail }}
            </div>
            <div v-else>
              {{ data.item.fi_name }}
            </div>
          </template>
          <template v-slot:cell(bank_id)="data">
            <div v-if="data.item.bank_id">
              {{ data.item.bank_id }}
            </div>
            <div v-else>
              {{ data.item.fi_id }}
            </div>
          </template>
        </b-table>
      </b-card>
    </template>
  </PageCard>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import * as moment from 'moment'
import PageCard from '@/modules/core/components/layouts/PageCard'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
// api
import adminGroupsAPI from '@/modules/admin/api/groups'
import adminUsersAPI from '@/modules/admin/api/users'
import adminUserReportAPI from '@/modules/admin/api/user_activity_report'
import superAdminGroupsAPI from '@/modules/super_admin/api/groups'
import superAdminUsersAPI from '@/modules/super_admin/api/users'
import partnerGroupsAPI from '@/api/finapps/partner_admin/groups'
import downloadsAPI from '@/modules/core/api/downloads'
import userActivityReportAPI from '@/api/finapps/partner_admin/user_activity_report'

export default {
  name: 'UserActivity',
  components: {
    PageCard,
    Multiselect,
    Datepicker
  },
  mounted() {
    this.getUserGroups()

    if (this.isClientAdmin) {
      this.getUserActivityReport()
    }
  },
  props: {
    role: {
      type: String,
      requred: true,
      default: ''
    }
  },
  data() {
    return {
      users: [],
      groups: [],
      reportTypes: [],
      usersList: [],
      groupsList: [],
      userReports: [],
      endDate: null,
      startDate: null,
      fetchingUsers: false,
      fetchingReports: false,
      showReports: false,
      reportFields: [],
      reportScheduled: false,
      reportTypesList: [
        { value: 'login', text: 'Login - UI' },
        { value: 'login - Direct URL', text: 'Login - Direct URL' },
        { value: 'download', text: 'Download' },
        { value: 'report_view', text: 'Report View - UI' },
        { value: 'report_view - Direct URL', text: 'Report View - Direct URL' },
        { value: 'bank_service_order', text: 'Bank Service Order' },
        { value: 'peer_group_created', text: 'Peer Group Created' },
        { value: 'fi_order', text: 'Financial Institution Order' }
      ]
    }
  },
  computed: {
    isSuperAdmin() {
      return this.role === 'super_admin'
    },
    isPartnerAdmin() {
      return this.role === 'partner_admin'
    },
    isClientAdmin() {
      return this.role === 'admin'
    },
    showSubscription() {
      return this.isClientAdmin
    },
    reportTypeValues() {
      return this.reportTypes.map(report => report.value)
    },
    selectAllText() {
      return this.allUsersSelected ? 'Clear All' : 'Select All'
    },
    allUsersSelected() {
      return this.users.length === this.usersList.length
    },
    baseFields() {
      return [
        { key: 'id', label: 'User ID', sortable: true },
        { key: 'first_name', label: 'First Name', sortable: true },
        { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'group_id', label: 'Group ID', sortable: true },
        { key: 'group_name', label: 'Group Name', sortable: true },
        { key: 'ip', label: 'IP', sortable: true },
        { key: 'time', label: 'Time', sortable: true },
        { key: 'event', label: 'Event', sortable: true }
      ]
    },
    peerGroupFields() {
      return [{ key: 'event_detail', label: 'Event Detail', sortable: true }]
    },
    reportViewFields() {
      return [
        { key: 'event_detail', label: 'Event Detail', sortable: true },
        { key: 'bank_id', label: 'Bank ID', sortable: true },
        { key: 'report_type', label: 'Report Type', sortable: true }
      ]
    },
    validForScheduledReport() {
      this.$v.$reset()
      this.$v.groups.$touch()
      this.$v.users.$touch()
      this.$v.reportTypes.$touch()

      return (
        !this.$v.groups.$error &&
        !this.$v.users.$error &&
        !this.$v.reportTypes.$error
      )
    }
  },
  methods: {
    getUserGroups() {
      this.$wait.start('fetchingGroups')
      let groupsAPI = this.isSuperAdmin
        ? superAdminGroupsAPI
        : this.isPartnerAdmin
        ? partnerGroupsAPI
        : adminGroupsAPI
      return groupsAPI.userGroups().then(res => {
        this.groupsList = res.groups
        this.$wait.end('fetchingGroups')
      })
    },
    getGroupUsers() {
      this.$v.groups.$touch()
      if (!this.$v.groups.$error) {
        this.users = []
        this.usersList = []
        this.fetchingUsers = true
        let groupIDs = this.groups.map(group => group.id)
        let usersAPI = this.isSuperAdmin
          ? superAdminUsersAPI
          : this.isPartnerAdmin
          ? partnerGroupsAPI
          : adminUsersAPI

        return usersAPI.groupUsers(groupIDs).then(res => {
          this.usersList = res.users
          this.fetchingUsers = false
        })
      }
    },
    getUserActivityReport() {
      this.$wait.start('fetchingUserSchedule')
      return adminUserReportAPI.userActivityReport().then(res => {
        this.reportScheduled = res.enabled
        this.$wait.end('fetchingUserSchedule')
      })
    },
    fetchActivityReport() {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.userReports = []
        this.showReports = true
        this.fetchingReports = true
        let activityReportAPI = this.isPartnerAdmin
          ? userActivityReportAPI
          : adminUsersAPI

        return activityReportAPI
          .activityReport({
            groupIDs: this.groups.map(group => group.id),
            userIDs: this.users.map(user => user.id),
            reportTypes: this.reportTypes.map(report => report.value),
            startDate: this.startDate,
            endDate: this.endDate
          })
          .then(res => {
            this.userReports = res.users
            var events = {}
            this.userReports.forEach(function(item) {
              events[item.event_id] = events[item.event_id] || item
            })
            this.userReports = Object.values(events)
            this.fetchingReports = false
          })
      }
    },
    selectOrRemoveAllUsers() {
      this.users = this.allUsersSelected ? [] : this.usersList
    },
    resetQuery() {
      this.groups = []
      this.users = []
      this.reportTypes = []
      this.startDate = null
      this.endDate = null
      this.userReports = []
      this.showReports = false
      this.$v.$reset()
    },
    validateUsers() {
      this.$v.users.$touch()
    },
    validateStartDate() {
      this.$v.startDate.$touch()
    },
    validateEndDate() {
      this.$v.endDate.$touch()
    },
    enableScheduledReport() {
      if (this.validForScheduledReport) {
        this.$wait.start('fetchingUserSchedule')
        return adminUserReportAPI
          .enableMonthlyReport({
            groupIDs: this.groups.map(group => group.id),
            userIDs: this.users.map(user => user.id),
            reportTypes: this.reportTypes.map(report => report.value)
          })
          .then(res => {
            this.$wait.end('fetchingUserSchedule')
            if (res.success) {
              this.$toasted.show('Montly report scheduled successfully.', {
                icon: 'user-circle',
                type: 'success'
              })
            }
            this.reportScheduled = res.success
          })
      } else {
        this.$toasted.show(
          'Please set mandatory field values for scheduled report delivery.',
          {
            icon: 'user-circle',
            type: 'error'
          }
        )
      }
    },
    updateScheduledReport() {
      if (this.validForScheduledReport) {
        this.$wait.start('fetchingUserSchedule')
        return adminUserReportAPI
          .updateMonthlyReport({
            groupIDs: this.groups.map(group => group.id),
            userIDs: this.users.map(user => user.id),
            reportTypes: this.reportTypes.map(report => report.value)
          })
          .then(res => {
            this.$wait.end('fetchingUserSchedule')
            if (res.success) {
              this.$toasted.show('Montly report scheduled successfully.', {
                icon: 'user-circle',
                type: 'success'
              })
            }
            this.reportScheduled = res.success
          })
      } else {
        this.$toasted.show(
          'Please set mandatory field values for scheduled report delivery.',
          {
            icon: 'user-circle',
            type: 'error'
          }
        )
      }
    },
    disableScheduledReport() {
      this.$wait.start('fetchingUserSchedule')
      return adminUserReportAPI.disableMonthlyReport().then(res => {
        this.$wait.end('fetchingUserSchedule')
        this.$toasted.show('Successfully removed scheduled montly report.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.reportScheduled = !res.success
      })
    },
    downloadCSVReport() {
      let fieldKeys = this.reportFields.map(field => field.key)
      let csv = this.reportFields.map(field => field.label).join(',') + '\n'

      this.userReports.forEach(report => {
        let csvRow =
          fieldKeys
            .map(key => (report[key] ? `"${report[key]}"` : ''))
            .join(',') + '\n'
        csv += csvRow
      })

      downloadsAPI.downloadCSVResult(csv, 'user_activity_report.csv')
    }
  },
  watch: {
    reportTypes: {
      handler: function() {
        this.showReports = false
        if (
          this.reportTypes.length === 1 &&
          (this.reportTypeValues.includes('login') ||
            this.reportTypeValues.includes('login - Direct URL'))
        ) {
          this.reportFields = this.baseFields
        } else if (
          this.reportTypes.length === 2 &&
          this.reportTypeValues.includes('login') &&
          this.reportTypeValues.includes('login - Direct URL')
        ) {
          this.reportFields = this.baseFields
        } else if (
          this.reportTypes.length === 3 ||
          this.reportTypeValues.includes('report_view') ||
          this.reportTypeValues.includes('report_view - Direct URL')
        ) {
          this.reportFields = this.baseFields.concat(this.reportViewFields)
        } else {
          this.reportFields = this.baseFields
        }
        if (this.reportTypeValues.includes('peer_group_created')) {
          this.reportFields = this.reportFields.concat(this.peerGroupFields)
        }
      }
    }
  },
  validations: {
    groups: {
      required
    },
    users: {
      required
    },
    reportTypes: {
      required
    },
    startDate: {
      required,
      isBefore(date) {
        if (!date || !this.endDate) {
          return true
        }
        let formattedEndDate = moment(this.endDate)
        return moment(date).isBefore(formattedEndDate)
      }
    },
    endDate: {
      required,
      isAfter(date) {
        if (!date || !this.startDate) {
          return true
        }
        let formattedStartDate = moment(this.startDate)
        return moment(date).isAfter(formattedStartDate)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-report {
  font-size: 1.2rem;
}

.card--report-schedule {
  .schedule-header {
    background-color: #c8d3d9;
  }

  .schedule-body {
    background-color: #f0f3f5;
  }
}

.btn-enable {
  color: white;
}
</style>

<style lang="scss">
.activity-filter-form {
  .form-control[readonly] {
    background-color: white;
  }

  .start-date-picker,
  .end-date-picker {
    display: flex;
    flex-basis: inherit;
    padding: 0 0 !important;

    .input-group {
      border-radius: 5px;
    }
  }
}

.card--activity-report {
  .download-icon {
    cursor: pointer;
  }
}

.date-col {
  .form-control {
    background-color: white;
    border-radius: 4px;
  }

  &.is-valid {
    .vdp-datepicker {
      .form-control {
        border-color: #79c447;
      }
    }

    .invalid-feedback {
      display: none;
    }
  }

  &.is-invalid {
    .vdp-datepicker {
      .form-control {
        border-color: #ff5454;
      }
    }

    .invalid-feedback {
      display: block;
    }
  }
}
</style>
